import { useState, useEffect } from 'react'
import Home from './body/Home.jsx'
import About from './body/About.jsx'
import Contact from './body/Contact.jsx'
import Menu from './body/Menu.jsx'
import Order from './body/Order.jsx'

import MENU from '../public/labMenu.js'

const Body = ({ page }) => {

  const [state, setState] = useState({
    menu: []
  })

  const renderBody = () => {
    if (page === 'HOME') {
      return (<Home />)
    } else if (page === 'ABOUT') {
      return (<About />)
    } else if (page === 'SOCIAL') {
      return (<Contact />)
    } else if (page === 'MENU') {
      return (<Menu menu={state.menu}/>)
    } else if (page === 'ORDER') {
      return (<Order />)
    }
  }

  const setMenu = (menu) => {
    setState(prevState => {
      return {
        ...prevState,
        menu
      }
    })
  }

  useEffect(() => {
    setMenu(MENU)
  }, [state.menu])

  useEffect(() => {
    window.scrollTo(0,0)
  },[page])

  return (
    <div className='body'>
      {renderBody()}
    </div>
  )
}

export default Body
