// import logo from './logo.svg';
// import './splash-page.scss';
import './main-page.scss';

// import SplashPage from './splash/SplashPage.jsx';
import MainPage from './components/MainPage.jsx';


function App() {
  return (
    <div className="main-app">
      {/*<SplashPage /> */}
      <MainPage />
    </div>
  );
}

export default App;
