import { useState } from 'react'
import logo from '../public/logos/freeform-logo-3.png'
import { slide as Menu } from 'react-burger-menu'

const MobileNav = ({ setPage }) => {

  const [state, setState] = useState({
    menuOpen: false
  })

  const closeMenu = () => {
    setState(prevState => {
      return {
        ...prevState,
        menuOpen: false
      }
    })
  }

  const handleStateChange = (state) => {
    setState({menuOpen: state.isOpen})
  }

  const handlePageChange = (page) => {
    closeMenu()
    setPage(page)
  }

  return (
    <div className='mobile-header'>
        <div className='mobile-logo-container'>
          <img src={logo} className='logo' alt='logo'></img>
          <div className='address'>
            <a
              className='header-link'
              href={"tel:+1201-261-7700"}
              target='_self'>
              {'(201)261-7700'}
            </a>
            <a
              className='header-link'
              href={"https://goo.gl/maps/j6sSPqFHLES3xZ4X8"}
              target='_blank'
              rel="noopener noreferrer">
              <div>{'235 Kinderkamack Rd.'}</div>
              <div>{'Oradell, NJ 07649'}</div>
            </a>
            <div className='hours'>{`Mon - Sat 11am - 9pm`}</div>
            <div className='hours'>{`Closed Sunday`}</div>
          </div>
        </div>
        <div className='mobile-nav'>
          <Menu
            noOverlay
            onClose={()=>{handlePageChange('HOME')}}
            onStateChange={()=>{handleStateChange(state)}}
            isOpen={state.menuOpen}
            right width={'100%'}
            disableAutoFocuss
            disableOverlayClick>
            <div key='HOME' onClick={()=>{handlePageChange('HOME')}}>HOME</div>
            <div key='MENU' onClick={()=>{handlePageChange('MENU')}}>MENU</div>
            <div key='ORDER' onClick={()=>{handlePageChange('ORDER')}}>ORDER</div>
            <div key='ABOUT' onClick={()=>{handlePageChange('ABOUT')}}>OUR STORY</div>
            <div key='SOCIAL'onClick={()=>{handlePageChange('SOCIAL')}}>SOCIAL</div>
          </Menu>
        </div>
    </div>
  )
}

export default MobileNav
