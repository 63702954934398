import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import brooklyn from '../../public/carousel-pictures/the_brooklyn.JPG'
import onion from '../../public/carousel-pictures/onion_rings.JPG'
import chicago from '../../public/carousel-pictures/the_chicago_dog.jpg'
import italian from '../../public/carousel-pictures/the_italian_dog.jpg'
import minx from '../../public/carousel-pictures/the_minx.JPG'
// import morning from '../../public/carousel-pictures/the_morning_after.jpg'
import original from '../../public/carousel-pictures/the_original.jpg'
import something from '../../public/carousel-pictures/the_something_bacon_and_bleu.jpg'
import spicy from '../../public/carousel-pictures/the_spicy.JPG'
import tree from '../../public/carousel-pictures/the_tree_hugger.jpg'


const captionStyle = {
  transform: 'translateY(50px)',
  fontSize: '26px',
  fontFamily: 'Mattilda',
  background: 'transparent'
}

const Home = () => {
  return (
    <>
    <div className='home-container'>
    <div className='home'>
      <Carousel className='carousel' showThumbs={false}>
        <div>
          <img className='carousel-picture'alt="" src={original} />
          <p className="legend" style={captionStyle}>The Original</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={brooklyn} />
          <p className="legend" style={captionStyle}>The Brooklyn</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={onion} />
          <p className="legend" style={captionStyle}>Onion Rings</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={chicago} />
          <p className="legend" style={captionStyle}>The Chicago Dog</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={italian} />
          <p className="legend" style={captionStyle}>The Italian Dog</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={minx} />
          <p className="legend" style={captionStyle}>The Minx</p>
        </div>
        {/*
        <div>
          <img className='carousel-picture'alt="" src={morning} />
          <p className="legend" style={captionStyle}>The Morning After</p>
        </div>
        */}
        <div>
          <img className='carousel-picture'alt="" src={something} />
          <p className="legend" style={captionStyle}>The Something Bacon and Bleu</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={spicy} />
          <p className="legend" style={captionStyle}>The Spicy</p>
        </div>
        <div>
          <img className='carousel-picture'alt="" src={tree} />
          <p className="legend" style={captionStyle}>The Tree Hugger</p>
        </div>
      </Carousel>
    </div>
    </div>
    <div className='home-bottom-spacer'>
    </div>
    </>
  )
}

export default Home
