const Menu = [

  { "section": "Burgers",
    "subheading": "All burgers made with 100% beef, supplied by Pat LaFrieda Meat Purveyors",
    "items": [
        {
          "name": "The Original",
          "description": "American cheese, mayo, ketchup, lettuce, tomato, onion, pickles, on a brioche bun",
          "price": "$11.50"
        },
        {
          "name": "The Remake",
          "description": "American cheese, mayo, hot sauce, lettuce, grilled onions, sweet and spicy pickles, on a brioche bun",
          "price": "$12.50"
        },
        {
          "name": "The Lab",
          "description": "Provolone cheese, pickled hot cherry peppers, grilled onions, mayo, on a ciabatta roll",
          "price": "$12.50"
        },
        {
          "name": "The Something Bacon and Bleu",
          "description": "Applewood smoked bacon, bleu cheese, caramelized onions and mushrooms, on a brioche bun",
          "price": "$13.50"
        },
        {
          "name": "The Texas Hold ‘Em",
          "description": "Sharp cheddar cheese, applewood smoked bacon, house made BBQ sauce, crispy onions, on a brioche bun",
          "price": "$13.50"
        },
        {
          "name": "The Jalapeno Hat Trick",
          "description": "Pepper jack cheese, pickled jalapeno, caramelized onions, hot sauce, on a sweet Hawaiian roll",
          "price": "$13.50"
        },
        {
          "name": "The Morning After",
          "description": "Fried cage-free organic egg, sliced avocado, applewood smoked bacon, mayo, on a toasted English muffin",
          "price": "$12.50"
        },
        {
          "name": "The Hawaiian",
          "description": "Grilled pineapple ring, teriyaki sauce, Bibb lettuce, mayo, on a sweet Hawaiian roll",
          "price": "$12.50"
        },
        {
          "name": "The Happy Accident",
          "description": "Stuffed with a combination of American and sharp cheddar cheese, lettuce, tomato, mayo, onion, ketchup, on a brioche bun",
          "price": "$12.50"
        },
        {
          "name": "The Try Hard",
          "description": "Turkey burger, sliced avocado, Bibb lettuce, tomato, house made chipotle mayo, on a 9-grain bun",
          "price": "$11.25"
        },
        {
          "name": "The Tree Hugger",
          "description": "House made veggie burger, sliced avocado, lettuce, tomato, red onion, chipotle mayo, on a 9-grain bun",
          "price": "$11.25"
        }
    ]
  },
  { "section": "Chicken Sandwiches",
    "subheading": "All chicken sandwiches made with organic chicken breast supplied by Goffle Brook Farms",
    "items": [
      {
        "name": "The Crispy",
        "description": "Buttermilk fried chicken breast, lettuce, mayo, dill pickles, on a brioche bun",
        "price": "$11.50"
      },
      {
        "name": "The Spicy",
        "description": "Buttermilk fried chicken breast slathered in house made buffalo sauce, with buttermilk Bleu cheese dressing, on a brioche bun",
        "price": "$11.50"
      },
      {
        "name": "The Southern",
        "description": "Buttermilk fried chicken breast, Mike’s Hot Honey, dill pickles, on a brioche bun",
        "price": "$11.50"
      },
      {
        "name": "The Brooklyn",
        "description": "Buttermilk fried chicken breast with Mike’s Hot Honey between two house made buttermilk waffles, served with whipped butter",
        "price": "$12.95"
      },
      {
        "name": "The Chicken Tendies",
        "description": "Five pieces of buttermilk fried chicken tenders with a side of hand cut fries",
        "price": "$10.95"
      }
    ]
  },
  { "section": "Hotdogs",
    "subheading": "All hot dogs are 100% beef, supplied by Pat LaFrieda Meat Purveyors",
    "items": [
      {
        "name": "The Old Dog",
        "description": "Grilled on a brioche bun",
        "price": "$6.95"
      },
      {
        "name": "The DC Dog",
        "description": "Grilled with chili & cheese on a brioche bun",
        "price": "$7.95"
      },
      {
        "name": "The Chicago Dog",
        "description": "Grilled with yellow mustard, sweet green pickle relish, onion, tomato wedges, and banana peppers on a brioche bun",
        "price": "$7.95"
      },
      {
        "name": "The Italian Dog",
        "description": "Grilled with potato, pepper and onion on brioche bun",
        "price": "$7.95"
      },
      {
        "name": "The Mac Daddy Dog",
        "description": "Grilled and topped with mac and cheese and applewood smoked bacon",
        "price": "$8.95"
      }
    ]
  },
  {
    "section": "Appetizers",
    "subheading": null,
    "items": [
      {
        "name": "Wings",
        "description": "Twelve organic chicken wings served with bleu cheese & celery. Choice of Buffalo, BBQ, teriyaki, lemon pepper or plain",
        "price": "$12.95"
      },
      {
        "name": "Mac & Chili Bowl",
        "description": "House made mac & cheese topped with house made chili, jalapenos & crispy onions",
        "price": "$9.95"
      },
      {
        "name": "Mac & Cheese Bites",
        "description": "Our house made mac & cheese, deep fried and topped with more cheese sauce",
        "price": "$7.00"
      },
      {
        "name": "Chicken Quesadilla",
        "description": "Organic seasoned chicken breast, pepper jack and cheddar cheese, wrapped in a flour tortilla served with a side of sour cream and house made guacamole",
        "price": "$10.95"
      },
      {
        "name": "Sampler Platter",
        "description": "Wings, fried pickles, onion rings and mac & cheese bites served with a side of bleu cheese and ranch",
        "price": "$12.95"
      },
    ]
  },
  {
    "section": "Soups & Sandwiches",
    "subheading": null,
    "items": [
      {
        "name": "Grilled Cheese",
        "description": "American and cheddar cheese between two pieces of texas toast",
        "price": "$5.95"
      },
      {
        "name": "Bacon Lettuce Tomato Avocado",
        "description": "Applewood smoked bacon, bibb lettuce, beef steak tomato, and avocado with house made chipotle mayo on texas toast, served with a side of sweet potato fries",
        "price": "$9.95"
      },
      {
        "name": "Tomato Soup",
        "description": "House made, tomato",
        "price": "$4.95"
      },
      {
        "name": "Butternut Squash Soup",
        "description": "House made, butternut squash",
        "price": "$4.95"
      },
      {
        "name": "French Onion Soup",
        "description": "House made, French onion",
        "price": "$4.95"
      },
    ]
  },
  {
    "section": "For the Lil' Pups",
    "subheading": "Kids meals and smaller portion sliders",
    "items": [
      {
        "name": "Lil' Cheeseburger Combo",
        "description": "American cheese, pickles, and a 3oz burger on a small bun with a small side of hand cut fries",
        "price": "$8.50"
      },
      {
        "name": "Lil' Hamburger Combo",
        "description": "A 3oz burger on a small bun with a small side of hand cut fries",
        "price": "$7.95"
      },
      {
        "name": "Lil' Chicken Tendies Combo",
        "description": "Three buttermilk fried chicken tenders with a small side of fries",
        "price": "$7.95"
      },
      {
        "name": "Lil' Dog Combo",
        "description": "Kids hot dog with a small side of fries",
        "price": "$7.95"
      },
      {
        "name": "Cheeseburger Slider Combo",
        "description": "Two cheeseburger sliders and a small side of fries",
        "price": "$9.50"
      },
      {
        "name": "Hamburger Slider Combo",
        "description": "Two Hamburger sliders and a small side of fries",
        "price": "$8.95"
      },
      {
        "name": "Chicken Slider Combo",
        "description": "Two buttermilk fried chicken sliders and a small side of fries",
        "price": "$7.95"
      },
      {
        "name": "Single Cheeseburger Slider",
        "description": "",
        "price": "$4.00"
      },
      {
        "name": "Single Hamburger Slider",
        "description": "",
        "price": "$3.75"
      },
      {
        "name": "Single Chicken Slider",
        "description": "",
        "price": "$3.00"
      },
    ]
  },
  { "section": "Sides",
    "subheading": null,
    "items": [
      {
        "name": "Hand Cut Fries",
        "description": null,
        "price": "$4.00"
      },
      {
        "name": "Hand Cut Sweet Potato Fries",
        "description": null,
        "price": "$5.00"
      },
      {
        "name": "Chili Cheese Fries",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Garlic Parm Fries",
        "description": null,
        "price": "$6.50"
      },
      {
        "name": "Homemade Battered Onion Rings",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Half & Half",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Fried Pickles",
        "description": null,
        "price": "$7.00"
      },
      {
        "name": "Mac & Cheese",
        "description": null,
        "price": "$7.00"
      },
      {
        "name": "Chili",
        "description": null,
        "price": "$7.00"
      }
    ]
  },
  { "section": "Sauces and Toppings",
    "subheading": null,
    "items": [
      {
        "name": "Mayo",
        "description": null,
        "price": "N/C"
      },
      {
        "name": "Ketchup",
        "description": null,
        "price": "N/C"
      },
      {
        "name": "Hot Sauce",
        "description": null,
        "price": "N/C"
      },
      {
        "name": "Yellow Mustard",
        "description": null,
        "price": "N/C"
      },
      {
        "name": "Red Onions",
        "description": null,
        "price": "N/C"
      },
      {
        "name": "Relish",
        "description": null,
        "price": "$0.25"
      },
      {
        "name": "Sauerkraut",
        "description": null,
        "price": "$0.25"
      },
      {
        "name": "Pickles",
        "description": null,
        "price": "$0.25"
      }

    ]
  },
  { "section": "Premium Sauces and Toppings",
    "subheading": null,
    "items": [
      {
        "name": "Chipotle Mayo",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "House Made Ketchup",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Buttermilk Ranch Sauce",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Buttermilk Bleu Cheese",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Hot Cherry Peppers",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Grilled Onions",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "House Made BBQ Sauce",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Sautéed Mushrooms",
        "description": null,
        "price": "$0.50"
      },
      {
        "name": "Applewood Smoked Bacon",
        "description": null,
        "price": "$1.50"
      }
    ]
  },
  { "section": "Desserts",
    "subheading": "All desserts made in house, rotated weekly",
    "items": [
      {
        "name": "Chocolate Chip Cookie",
        "description": null,
        "price": "$2.50"
      },
      {
        "name": "Black & White Cookie",
        "description": null,
        "price": "$3.00"
      },
      {
        "name": "Whoopie Pie",
        "description": null,
        "price": "$4.00"
      },
      {
        "name": "Cupcake",
        "description": null,
        "price": "$2.00"
      },
      {
        "name": "Brownie",
        "description": null,
        "price": "$4.00"
      },
      {
        "name": "Banana Pudding",
        "description": null,
        "price": "$5.00"
      },
      {
        "name": "Oreo Pudding",
        "description": null,
        "price": "$5.00"
      },
      {
        "name": "The Minx Cookie",
        "description": null,
        "price": "$3.00"
      }
    ]
  },
  { "section": "Milkshakes",
    "subheading": "16oz",
    "items": [
      {
        "name": "Vanilla",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Chocolate",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Strawberry",
        "description": null,
        "price": "$6.00"
      },
      {
        "name": "Specialty Shakes",
        "description": null,
        "price": "$7.00"
      }
    ]
  },
  { "section": "Drinks",
    "subheading": null,
    "items": [
      {
        "name": "Boylan Bottled Sodas",
        "description": null,
        "price": "$2.50"
      },
      {
        "name": "Boylan Bottled Seltzer",
        "description": null,
        "price": "$2.50"
      },
      {
        "name": "Joe Tea",
        "description": null,
        "price": "$3.00"
      },
      {
        "name": "Glass Bottle Coca Cola",
        "description": null,
        "price": "$3.00"
      },
      {
        "name": "Poland Spring Water",
        "description": null,
        "price": "$1.25"
      }
    ]
  }
]

export default Menu
