import * as React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      {'Copyright The Lab Burger Co. 2021'}
    </div>
  )
}

export default Footer
