import * as React from 'react'

const Menu = ({ menu }) => {

  const renderMenuItems = () => {
    return menu.map(menuSection => {

      const renderSectionItems = () => {
        return menuSection.items.map(menuItem => {
          if (!menuItem.description) {
            console.log(menuItem.name)
            console.log(menuItem.description)
            return (
              <div className='section-item' key={menuItem.name}>
                <div className='item-name-crystal'>{menuItem.name}</div>
                <div className='item-price'>{menuItem.price}</div>
                <div className='item-description'>{menuItem.description}</div>
              </div>
            )
          } else {
            return (
              <div className='section-item' key={menuItem.name}>
                <div className='item-name-mattilda'>{menuItem.name}</div>
                <div className='item-price'>{menuItem.price}</div>
                <div className='item-description'>{menuItem.description}</div>
              </div>
            )
          }
        })
      }

      return (
        <div className='menu-section' key={menuSection.section}>
          <div className='section-title'>{menuSection.section}</div>
          <div className='section-subtitle'>{menuSection.subheading}</div>
          {renderSectionItems()}
        </div>
      )
    })
  }

  return (
    <div className='menu'>
      <div className='menu-title'>Our Menu</div>
      <div className='specials-content'>
        <div className='specials-title'>{"Lunch Specials 11:00a-3:00p, Monday - Friday"}</div>
        <div><b>All burgers, chicken sandwiches and hot dogs</b> come with a side of fries, onion rings or sweet potato fries</div>
      </div>
      <div className='menu-items-container'>
        {renderMenuItems()}
      </div>
    </div>
  )
}

export default Menu
