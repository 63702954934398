import { useState } from 'react'

import Header from './Header.jsx'
import MobileHeader from './MobileHeader.jsx'
import Body from './Body.jsx'
import Footer from './Footer.jsx'

export default function MainPage() {

  const [state, setState] = useState({
    page: 'HOME'
  })

  const setPage = (page) => {
    setState(prevState => {
      return {
        ...prevState,
        page
      }
    })
  }

  return(
    <div className='main-page'>
      <Header setPage={setPage}/>
      <MobileHeader setPage={setPage}/>
      <Body page={state.page}/>
      <Footer />
    </div>
  )
}
