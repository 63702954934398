import React from 'react';
import logo from '../public/logos/freeform-logo-3.png'

export default function Header({ setPage }) {
  return (
    <div className='header'>
      <img src={logo} className='logo' alt='logo'></img>
      <div className='address'>
        <a
          className='header-link'
          href={"tel:+1201-261-7700"}
          target='_self'>
          {`(201)261-7700`}
        </a>
        <a
          className='header-link'
          href={"https://goo.gl/maps/j6sSPqFHLES3xZ4X8"}
          target='_blank'
          rel="noopener noreferrer">
          <div>{'235 Kinderkamack Rd.'}</div>
          <div>{'Oradell, NJ 07649'}</div>
        </a>
        <div className='hours'>{`Mon - Sat: 11am - 9pm`}</div>
        <div className='hours'>{`Sunday: 12pm - 9pm`}</div>
      </div>
      <div className='nav-bar'>
        <div onClick={()=>{setPage('HOME')}}>HOME</div>
        <div onClick={()=>{setPage('MENU')}}>MENU</div>
        <div onClick={()=>{setPage('ORDER')}}>ORDER</div>
        <div onClick={()=>{setPage('ABOUT')}}>OUR STORY</div>
        <div onClick={()=>{setPage('SOCIAL')}}>SOCIAL MEDIA</div>
      </div>
    </div>
  )
}
