import * as React from 'react'
import { SocialIcon } from 'react-social-icons';

const Contact = () => {
  return (
    <div className='social'>
      <section className='contact'>
        <div className='contact-header'>Ready to order?</div>
        <div className='contact-phone'>(201) 261-7700</div>
      </section>
      <section className='social-media'>
        <span className='social-info-header'>Follow us below!</span>
        <div className='social-info'>
          <div className='social-group'>
          <SocialIcon url="mailto:info@thelabburger.co" bgColor='white' className='social-icons' target='_blank'/>
            <span className='social-handle'>info@thelabburger.co</span>
          </div>
          <div className='social-group'>
            <SocialIcon url="https://www.facebook.com/The-Lab-Burger-Co-100520392057765" bgColor='white' className='social-icons' target='_blank'/>
            <span className='social-handle'>@thelabburger.co</span>
          </div>
          <div className='social-group'>
            <SocialIcon url="https://www.instagram.com/thelabburger.co/" bgColor='white' className='social-icons' target='_blank'/>
            <span className='social-handle'>@thelabburger.co</span>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
