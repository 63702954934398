import * as React from 'react'

const Order = () => {

  const butterLink = "https://www.makeitbutter.com/the-lab-burger-co"
  const cateringLink= "https://www.ezcater.com/catering/the-lab-burger-co-3"

  const handleOrder = () => window.open(butterLink, "_blank")
  const handleCateringOrder = () => window.open(cateringLink, "_blank")

  return (
    <div className='order'>
      <div className='order-title'>Place your order now with free delivery: </div>
      <div className='button-group'>
        <div className='order-button' onClick={handleOrder}>Order</div>
      </div>

      <div className='order-title'>Or place a catering order here: </div>
      <div className='button-group'>
        <div className='catering-order-button' onClick={handleCateringOrder}>Order Catering</div>
      </div>
      
    </div>
  )
}

export default Order
