import * as React from 'react'

const About = () => {

  const renderText = () => {

    return (
      <div className='about-content'>
        <p><span className='first-word'>{"Brought"}</span>{" to you by the owners of Calabria Pizza, The Lab Burger Co. aims to be your favorite neighborhood spot to grab a great bite of classic American fare. We named this restaurant as a nod to our original restaurant, as well as an homage to our beloved Black Lab who's passed on, Minx. We decided to model our mascot after Minx, and incorporate some elements of her in this restaurant because we absolutely loved her and she was one of our biggest sources of comfort. We hope The Lab Burger Co. can provide a source of comfort for you too."}</p>

        <p><span className='first-word'>{"Our"}</span>{" goal for The Lab Burger Co. is to offer traditional American comfort foods with a modern and healthier twist. Our 100% grass-fed beef is supplied by Pat LaFrieda Meat Purveyors, our organic chicken breast, turkey burgers and cage-free eggs are supplied by Goffle Brook Farms, our brioche buns come from Hudson Bakery, and our pickles are from Pickle Licious. We work with local, small businesses to try to make our food as wholesome as possible. All of our desserts are made in house, and we have a large selection of sauces we've crafted for you by hand. We believe comfort food doesn’t have to be unhealthy—so we’re putting together whole food, all natural ingredients to make something comforting that’s maybe even good for you too. "}</p>
      </div>
    )
  }

  return (
    <div className='about'>
      <div className='about-title'>Our Story</div>
      {renderText()}
    </div>
  )
}

export default About
